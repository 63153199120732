<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Producenter
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          label="Søg"
          hide-details
          class="mr-4"
          autofocus
          placeholder="Skriv for at søge"
          solo
          dense
          clearable
        />

        <v-menu
          v-model="addMenu"
          left
          :close-on-content-click="false"
          min-width="500"
        >
          <template #activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
            >
              Tilføj
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Ny producent
            </v-card-title>

            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="newItem.title"
                  label="Navn"
                  :rules="[ v => !!v || 'Udfyld et navn' ]"
                />

                <v-combobox
                  v-model="newItem.aliases"
                  label="Aliaser"
                  hint="Brug TAB til at tilføje flere"
                  multiple
                  chips
                  deletable-chips
                />
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                @click="clear"
              >
                Annuller
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                :disabled="!valid"
                @click="add"
              >
                Tilføj
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
          :search.sync="search"
          :sort-by="[ 'title' ]"
          :items-per-page="15"
        >
          <template #[`item.title`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.title"
              persistent
              large
              save-text="Gem"
              cancel-text="Annuller"
              @save="save(item)"
            >
              {{ item.title }}

              <template #input>
                <v-text-field
                  v-model="item.title"
                  label="Navn"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template #[`item.aliases`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.aliases"
              persistent
              large
              save-text="Gem"
              cancel-text="Annuller"
              @save="save(item)"
            >
              <v-chip-group>
                <v-chip
                  v-for="alias in item.aliases"
                  :key="alias"
                  small
                  label
                >
                  {{ alias }}
                </v-chip>
              </v-chip-group>

              <template #input>
                <v-card-title>
                  {{ item.title }}
                </v-card-title>
                <v-combobox
                  v-model="item.aliases"
                  :items="item.aliases"
                  multiple
                  chips
                  deletable-chips
                />
              </template>
            </v-edit-dialog>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn icon :to="item.id" append>
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>


        </v-data-table>
      </v-card-text>

      <v-card-text>
        <div class="text-caption">
          Hint: Klik på den celle i tabellen du vil redigere
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Manufacturers',
  data() {
    return {
      addMenu: false,
      items: [],
      loading: false,
      headers: [
        { text: 'Navn', value: 'title', width: '25%' },
        { text: 'Aliaser', value: 'aliases', width: '60%' },
        { text: 'Kode', value: 'code', align: 'end' }
      ],
      newItem: {
        title: '',
        aliases: []
      },
      valid: false
    }
  },
  computed: {
    search: {
      get() {
        return this.$route.query.search
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: value ?? undefined
          }
        })
      }
    }
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async add() {
      try {
        const newItemTitle = this.newItem.title;
        const response = await this.$AuthService.api.post('manufacturer', this.newItem);

        this.$notifier.showSuccess({ message: 'Producent blev tilføjet' });
        this.clear();
        await this.fetchData();
        this.search = newItemTitle;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der skete en fejl. Prøv venligst igen.' });
      }
    },
    clear() {
      this.addMenu = false;
      this.newItem = {
        title: '',
        aliases: []
      };
    },
    async fetchData() {
      try {
        this.loading = true;

        const response = await this.$AuthService.api.get('manufacturer');

        this.items = response.data;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.loading = false;
      }
    },
    async save(manufacturer) {
      try {
        await this.$AuthService.api.put(`manufacturer/${manufacturer.id}`, manufacturer);

        this.$notifier.showSuccess({ message: 'Ændringen blev gemt' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Ændringen blev ikke gemt!' });
      }
    }
  }
}
</script>
